import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { BookingState } from '../reducers/booking.reducer';
import { Booking } from 'src/app/types/booking';

export const selectBookingState = (state: AppState) => state.booking;

export const selectBookingStore = createSelector(
  selectBookingState,
  (state: BookingState) => state
)

export const selectIsLoading = createSelector(
  selectBookingState,
  (state: BookingState) => state.loading
)


export const selectBookingError = createSelector(
  selectBookingState,
  (state: BookingState) => state.error
)

export const selectCoupon = createSelector(
  selectBookingState,
  (state: BookingState) => state.coupon
)

export const selectTicket = createSelector(
  selectBookingState,
  (state: BookingState) => state.ticket
)

export const selectStart = createSelector(
  selectBookingState,
  (state: BookingState) => state.start
)

export const selectEnd = createSelector(
  selectBookingState,
  (state: BookingState) => state.end
)

export const selectSelectedBillingAddress = createSelector(
  selectBookingState,
  (state: BookingState) => state.selectedBillingAddress
)

export const selectSelectedPaymentMethod = createSelector(
  selectBookingState,
  (state: BookingState) => state.selectedPaymentMethod
)