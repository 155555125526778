import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthResponse, Credentials } from 'src/app/types/auth';
import { tap } from 'rxjs/operators';
import { NationalNumber } from 'libphonenumber-js/types';
import { Store } from '@ngrx/store';
import { selectAuthRegisterCountry } from '../store/selectors/auth.selectors';
import { selectLocalization } from '../store/selectors/localization.selectors';

export interface ChangePasswordPayload {
  current: string,
  password: string,
  password_confirmation: string,
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private store: Store
  ) { }

  login(credentials: Credentials) {
    return this.httpClient.post(`${environment.apiUrl}auth/login`, credentials)
  }

  register(user: any) {
    return this.httpClient.post(`${environment.apiUrl}auth/register`, user);
  }

  verifyCode(code: string) {
    return this.httpClient.post(`${environment.apiUrl}auth/phone/verification`, { code });
  }

  deleteAccount() {
    return this.httpClient.delete(`${environment.apiUrl}me/account`);
  }

  sendCode(phone: NationalNumber, indicator: any) {
    indicator = '+' + indicator;
    let language = 'fr-FR';
    this.store.select(selectLocalization).subscribe(lang => {
      language = lang
    })

    return this.httpClient.post(`${environment.apiUrl}auth/phone/send`, { phone, indicator, language });
  }

  logout(): Observable<any> {
    return this.httpClient.post(`${environment.apiUrl}auth/logout`, {});
  }

  updateProfile(data: any) {
    return this.httpClient.put(`${environment.apiUrl}me/profile`, data);
  }

  forgotPassword(data: any) {
    return this.httpClient.post(`${environment.apiUrl}auth/forgot-password`, data);
  }

  changePassword(data: ChangePasswordPayload) {
    return this.httpClient.put(`${environment.apiUrl}me/change-password`, data)
  }
}
