import { HttpErrorResponse } from '@angular/common/http';
import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { AuthState } from '../reducers/auth.reducer';
import { RideState } from '../reducers/ride.reducer';
import { selectBookingState } from './booking.selectors';
import { BookingState } from '../reducers/booking.reducer';
import { Booking } from 'src/app/types/booking';

export const selectRideState = (state: AppState) => state.ride;

export const selectCurrentBooking = createSelector(
  selectRideState,
  (state: RideState) => state.booking
);

export const selectCurrentBookingIsExtendable = createSelector(
  selectCurrentBooking,
  (state: Booking | null) => state?.isExtendable
);

export const selectCurrentBookingDynamicBufferEndAt = createSelector(
  selectCurrentBooking,
  (state: Booking | null) => state?.dynamic_buffer_end_at
);

export const selectPodStatus = createSelector(
  selectRideState,
  (state: RideState) => state.podStatus
);

export const selectDoorAndPresenceStatus = createSelector(
  selectRideState,
  (state: RideState) => ({
    door: state.podStatus?.door,
    presence: state.podStatus?.presence,
  })
);

export const selectPodDoorStatus = createSelector(
  selectRideState,
  (state: RideState) => state.podStatus?.door
);


export const selectPodPresenceStatus = createSelector(
  selectRideState,
  (state: RideState) => state.podStatus?.presence
);

export const selectBookingStatus = createSelector(
  selectRideState,
  (state: RideState) => state.booking?.status
);

export const selectDynamicBufferEntAt = createSelector(
  selectRideState,
  (state: RideState) => state.booking?.dynamic_buffer_end_at
);

export const selectAutomaticBufferEndAt = createSelector(
  selectRideState,
  (state: RideState) => state.booking?.automatic_buffer_end_at
);

export const selectHasRunningBooking = createSelector(
  selectRideState,
  (state: RideState) => state.booking && state.booking.status !== 'booked' ? true : false,
);

export const selectUserDigicode = createSelector(
  selectRideState,
  (state: RideState) => state.booking?.pod?.digicodes?.user
);

export const selectDigicodeSuffix = createSelector(
  selectRideState,
  (state: RideState) => state.booking?.pod?.digicodes?.suffix
);

export const selectQuestionnaire = createSelector(
  selectRideState,
  (state: RideState) => state.questionnaire
);
