import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of, from, interval } from 'rxjs';
import { catchError, concatMap, debounceTime, delay, exhaustMap, filter, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import {
  completeCheckinFailure,
  completeCheckinSuccess,
  completeCheckoutFailure,
  completeCheckoutSuccess,
  completeRideFailure,
  completeRideSuccess,
  COMPLETE_CHECKIN,
  COMPLETE_CHECKOUT,
  COMPLETE_RIDE,
  openKabinFailure,
  openKabinSuccess,
  OPEN_KABIN,
  OPEN_KABIN_SUCCESS,
  getPodStatusSuccess,
  getPodStatusFailure,
  COMPLETE_CHECKIN_SUCCESS,
  setBookingStatus,
  LOAD_CURRENT_BOOKING,
  loadCurrentBookingSuccess,
  loadCurrentBookingFailure,
  EXTEND_BOOKING,
  extendBookingSuccess, 
  extendBookingFailure,
  EXTEND_BOOKING_FAILURE,
  loadCurrentBooking,
  LOAD_CURRENT_BOOKING_SUCCESS,
  getPodStatus,
  GET_POD_STATUS,
  GET_POD_STATUS_SUCCESS,
} from '../actions/ride.actions';
import { ModalController } from '@ionic/angular';
import { Booking, ExtendPayload } from 'src/app/types/booking';
import { BookingService } from 'src/app/services/booking.service';
import { Store } from '@ngrx/store';
import { selectCurrentBooking } from '../selectors/ride.selectors';
import { selectIsGuest, selectConnectedUser } from '../selectors/auth.selectors';
import { RideService } from 'src/app/services/ride.service';
import { PodService } from 'src/app/services/pod.service';
import { PodStatus } from 'src/app/types/pod';
import { RidePage } from 'src/app/modals/ride/ride.page';
import { HttpErrorResponse } from '@angular/common/http';
import { loadNextBookingsSuccess } from '../actions/me.actions';
import { selectNextBookings } from '../selectors/me.selectors';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class RideEffects {
  openKabinOpenRideModal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OPEN_KABIN_SUCCESS),
      tap(async ({ booking }: { booking: Booking }) => {
        const modal = await this.modalController.create({
          component: RidePage,
          cssClass: 'modal-ride',
          componentProps: {
            booking,
          }
        });
        await modal.present();
      })
    )
    ,
    {
      dispatch: false
    }
  );

  loadCurrentBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_CURRENT_BOOKING),
      withLatestFrom(
        this.store.select(selectConnectedUser)
      ),
      filter(([,user]) => !!user),
      exhaustMap(() => {
        return this.bookingService.current().pipe(
          map((response: { booking: Booking }) => loadCurrentBookingSuccess(response)),
          catchError(error => of(loadCurrentBookingFailure(error)))
        );
      })
    )
  );

  openKabin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OPEN_KABIN),
      exhaustMap(({ booking }: { booking: Booking }) => {
        return this.rideService.access(booking).pipe(
          map(({ booking }: { booking: Booking }) => openKabinSuccess({ booking })),
          catchError(error => of(openKabinFailure(error)))
        );
      })
    )
  );


  openKabinSuccessUpdateNextBookings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OPEN_KABIN_SUCCESS),
      withLatestFrom(this.store.select(selectNextBookings)),
      mergeMap(([{ booking }, bookings]: [{ booking: Booking }, any]) => {
        return [
          loadNextBookingsSuccess({
            bookings: bookings.map((item: Booking) => item.uuid === booking.uuid ? booking : item)
          }),
          loadCurrentBookingSuccess({ booking }),
        ];
      })
    )
  );

  completeCheckin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(COMPLETE_CHECKIN),
      withLatestFrom(
        this.store.select(selectCurrentBooking)
      ),
      exhaustMap(([{ continue: pursue }, booking]: [{ continue: boolean }, Booking]) => {
        return this.rideService.completeCheckin(booking, { continue: pursue }).pipe(
          map(({ booking }: { booking: Booking }) => completeCheckinSuccess({ booking })),
          catchError(error => of(completeCheckinFailure(error)))
        );
      })
    )
  );

  handlePreRideStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(COMPLETE_CHECKIN_SUCCESS),
      filter(({ ride, booking }: { ride: boolean, booking: Booking }) => !ride && booking.status === 'ride'),
      tap(() => {
        setTimeout(() => {
          this.store.dispatch(setBookingStatus({ status: 'ride' }))
        }, 120000)
      })
    )
  , {
    dispatch: false
  });

  completeRide$ = createEffect(() =>
    this.actions$.pipe(
      ofType(COMPLETE_RIDE),
      withLatestFrom(
        this.store.select(selectCurrentBooking)
      ),
      exhaustMap(([, booking]: [unknown, Booking]) => {
        return this.rideService.completeRide(booking).pipe(
          map(({ status }: { status: string}) => completeRideSuccess({ status })),
          catchError(error => of(completeRideFailure(error)))
        );
      })
    )
  );

  completeCheckout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(COMPLETE_CHECKOUT),
      withLatestFrom(
        this.store.select(selectCurrentBooking)
      ),
      exhaustMap(([{ reports }, booking]: [any, Booking]) => {
        return this.rideService.completeCheckout(booking, reports).pipe(
          map(({ booking, questionnaire }: { booking: Booking, questionnaire: string}) => completeCheckoutSuccess({ booking, questionnaire })),
          catchError(error => of(completeCheckoutFailure(error)))
        );
      })
    )
  );

  extendBooking$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EXTEND_BOOKING),
      withLatestFrom(
        this.store.select(selectCurrentBooking)
      ),
      exhaustMap(([data, booking]: [ExtendPayload, Booking]) => {
        return this.rideService.extend(booking, data).pipe(
          map(({ booking }: { booking: Booking }) => {
            this.notificationService.notify({
              body: `Nous vous confirmons l\'extension de votre réservation pour une durée de ${data.amount} minutes`,
            });
            return extendBookingSuccess({ booking })
          }),
          catchError(error => of(extendBookingFailure(error)))
        );
      })
    )
  );

  extendBookingFailureReloadCurrent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EXTEND_BOOKING_FAILURE),
      tap(({ error }: HttpErrorResponse) => {
        if (error.error === 'not-expandable') {
          return this.store.dispatch(loadCurrentBooking())
        }
      })
    )
  , {
    dispatch: false
  });

  getPodStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(GET_POD_STATUS),
      withLatestFrom(
        this.store.select(selectCurrentBooking)
      ),
      exhaustMap(([, booking]: [unknown, Booking]) => {
        return this.podService.status(booking.pod).pipe(
          map((status: PodStatus) => getPodStatusSuccess({ status })),
          catchError(error => of(getPodStatusFailure({ error })))
        );
      })
    )
  );

  loadPodStatusIfCurrentBookingExists = createEffect(() =>
    this.actions$.pipe(
      ofType(LOAD_CURRENT_BOOKING_SUCCESS),
      filter(({ booking }: { booking: Booking }) => booking !== null),
      map(({ booking }: { booking: Booking }) => {
        return getPodStatus({ pod: booking.pod })
      })
    )
  );

  constructor(
    private actions$: Actions,
    private modalController: ModalController,
    private rideService: RideService,
    private bookingService: BookingService,
    private podService: PodService,
    private store: Store,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
  ) { }
}
