import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { IonicSlides } from '@ionic/angular';
import { addDays, addMinutes, differenceInMinutes, eachDayOfInterval, eachMinuteOfInterval, endOfMonth, format, getMonth, isAfter, isSameDay, roundToNearestMinutes, set, setMonth, startOfMonth, setDefaultOptions } from 'date-fns';
import { BehaviorSubject, Observable, forkJoin, of, Subject, combineLatest, interval, merge, Subscription } from 'rxjs';
import { enGB, fr } from 'date-fns/locale';
import { distinctUntilChanged, skip, switchMap, take, tap, map, mergeMap, withLatestFrom, exhaustMap, defaultIfEmpty, filter, skipUntil, startWith, delay } from 'rxjs/operators';
import { Availability, PodService } from 'src/app/services/pod.service';
import {
  chunk,
  drop,
  findIndex,
  isNull,
  take as _take,
  every as _every,
  reduce as _reduce,
  drop as _drop,
  takeWhile as _takeWhile,
  head as _head,
  find as _find,
  isUndefined as _isUndefined,
  last as _last,
  range as _range,
  map as _map,
  clone as _clone,
  cloneDeep as _cloneDeep,
} from 'lodash';
import { Pod } from 'src/app/types/pod';
import { Store } from '@ngrx/store';
import { setEnd, setStart } from 'src/app/store/actions/booking.actions';
import { selectBookingError } from 'src/app/store/selectors/booking.selectors';
import { HttpErrorResponse } from '@angular/common/http';
import { selectLocale, selectLocalization } from 'src/app/store/selectors/localization.selectors';
import { DateFnsConfigurationService, DateFnsModule } from 'ngx-date-fns';

type localeListType = {
  'fr-FR': Locale,
  'en-GB': Locale
}

@Component({
  selector: 'kabin-planner',
  templateUrl: './planner.component.html',
  styleUrls: ['./planner.component.scss'],
})
export class PlannerComponent implements OnInit, OnDestroy {

  @Input() pod: Pod;

  @Output() selection: EventEmitter<any> = new EventEmitter();
  @Output() onSelectionChange = new EventEmitter<{ from: Date, to: Date }>();

  @ViewChild('slideDayPicker', { static: true }) slideDayPicker: ElementRef | undefined;
  @ViewChild('slideTimepickerStart') slideTimepickerStart: ElementRef | undefined;
  @ViewChild('slideTimepickerEnd') slideTimepickerEnd: ElementRef | undefined;

  swiperModules = [IonicSlides];
  chunkSize = 6;
  currentDay = new Date();
  currentMonth = getMonth(new Date());
  bookingError$: Observable<HttpErrorResponse>;

  currentDayAvailabilities$ = new Subject<Availability[]>()
  availabilities$ = new Subject<Availability[]>()

  selectionType$ = new BehaviorSubject<'now' | 'schedule'>('now')
  selectedDay$ = new BehaviorSubject<Date>(new Date())
  selectedMonth$ = new BehaviorSubject<number>(getMonth(new Date()))
  selectedFrom$ = new BehaviorSubject<Availability>(null)
  selectedTo$ = new BehaviorSubject<Availability>(null)
  selectedDuration$ = new BehaviorSubject<number>(15)

  availableMonths: Array<number> = [];
  availableDays$: Observable<Date[]>
  availableFromSlots$: Observable<Availability[][]>
  availableToSlots$: Observable<Availability[][]>
  availableDurations$: Observable<number[]>
  availableUntil$: Observable<Date>
  
  isAvailableNow$: Observable<boolean>
  noAvailableSlotForSelectedDay$: Observable<boolean>
  isCurrentDaySelected$: Observable<boolean>

  currentLocale$: Observable<string>;

  subscriptions$: Subscription;

  localesList : localeListType = {
    'fr-FR': fr, 
    'en-GB': enGB
  };

  constructor(
    private podService: PodService,
    private store: Store,
    private config: DateFnsConfigurationService
  ) {
    // Logs
    this.selectedDay$.subscribe((day: Date) => {
      console.log('selectedDay : ', format(day, 'yyyy-MM-dd'), day)
    })
    this.selectedMonth$.subscribe((month: number) => {
      console.log('selectedMonth : ', format(setMonth(new Date(), month), 'LLLL'))
    })
    this.selectedFrom$.subscribe((slot: Availability) => {
      console.log('selectedFrom : ', slot)
    })
    this.selectedTo$.subscribe((slot: Availability) => {
      console.log('selectedTo : ', slot)
    })
    this.currentDayAvailabilities$.subscribe((slots: Availability[]) => {
      console.log('currentDayAvailabilities : ', slots)
    })
    this.availabilities$.subscribe((slots: Availability[]) => {
      console.log('availabilities : ', slots)
    })
    this.selectionType$.subscribe((type: any) => {
      console.log('selectionType : ', type)
    })
    this.subscriptions$ = new Subscription()
  }

  ngOnInit() {
    this.setAvailableMonths()

    this.store.select(selectLocalization).subscribe(language => {
      this.config.setLocale(this.localesList[language])
    })

    // Load availabilities
    const dayCalendarApiCall = merge(
      this.selectedDay$,
      this.store.select(selectBookingError).pipe(
        filter((v: HttpErrorResponse) => !isNull(v)),
      ),
    ).pipe(
      withLatestFrom(this.selectedDay$),
      switchMap(([, selectedDay]: [Date | HttpErrorResponse, Date]) => this.podService.calendar(this.pod, selectedDay))
    ).subscribe((availabilites: Availability[]) => {
      this.availabilities$.next(availabilites)
    })

    this.subscriptions$.add(dayCalendarApiCall);

    this.availableFromSlots$ = this.availabilities$.pipe(
      map((slots: Availability[]) => _cloneDeep(slots)),
      map((slots: Availability[]) => {
        return slots.map((slot: Availability, index: number) => {
          if (slot.isAvailable && slots[index + 1]?.isAvailable === false) {
            slot.isAvailable = false
            if (slots[index - 1]) {
              slots[index - 1].isAvailable = false
            }
          }
          return slot
        })
      }),
      map((slots: Availability[]) => slots.slice(0, slots.length - 2)),
      map((slots: Availability[]) => chunk(slots, this.chunkSize)),
    )

    const updateSliderOnFromSlotsChange = this.availableFromSlots$.pipe(delay(50)).subscribe((r) => {
      this.slideTimepickerStart?.nativeElement?.swiper?.update()
    })

    this.subscriptions$.add(updateSliderOnFromSlotsChange);


    // If loaded availabilities are equal of today, update current day availabilities
    const updateCurrentDayAvailabilities = this.availabilities$.pipe(
      withLatestFrom(this.selectedDay$),
      filter(([, selectedDay]: [Availability[], Date]) => isSameDay(this.currentDay, selectedDay)),
      map(([availabilities]: [Availability[], Date]) => availabilities)
    ).subscribe((availabilities: Availability[]) => {
      this.currentDayAvailabilities$.next(availabilities);
    })

    combineLatest([
      this.availabilities$.pipe(distinctUntilChanged()),
      this.selectionType$.pipe(filter(x => x === 'schedule'))
    ]).subscribe(([availabilities, type]: [Availability[], string]) => {
      this.selectFirstAvailableSlot(availabilities);
    })

    this.subscriptions$.add(updateCurrentDayAvailabilities);

    // Update available slots to depending on selected from and availabilities
    this.availableToSlots$ = combineLatest([
      this.selectedFrom$,
      this.availabilities$
    ]).pipe(
      switchMap(([from, availabilities]: [Availability | null, Availability[]]) => {
        return of(
          isNull(from) ?
          availabilities :
          drop(availabilities, findIndex(availabilities, (o: Availability) => o.from === from?.from) + 2)
        )
      }),
      map((slots: Availability[]) => chunk(slots, this.chunkSize))
    )

    const updateSliderOnToSlotsChange = this.availableToSlots$.pipe(delay(50)).subscribe((r) => {
      this.slideTimepickerEnd?.nativeElement?.swiper?.update()
    })

    this.subscriptions$.add(updateSliderOnToSlotsChange);


    // Swap selected day on month change
    const setDefaultDayOnMonthChange = this.selectedMonth$.pipe(
      skip(1),
    ).subscribe((month: number) => {
      const now = new Date();
      const currentYear = new Date().getFullYear();
      this.selectedDay$.next(
        month === this.currentMonth ?
          this.currentDay :
          startOfMonth(
            setMonth(now.setFullYear(month < this.currentMonth ? currentYear + 1 : currentYear), month) // We assume it's next year if selected month is inferior to current month
          )
      )
    })

    this.subscriptions$.add(setDefaultDayOnMonthChange);

    // Make list of availableDays depending on selected month
    this.availableDays$ = this.selectedMonth$.pipe(
      switchMap((month: number) => {
        const now = new Date();
        const currentYear = new Date().getFullYear();
        const randomDayInMonth = setMonth(now.setFullYear(month < this.currentMonth ? currentYear + 1 : currentYear), month)
        return of(
          eachDayOfInterval({
            start: month === this.currentMonth ? this.currentDay : startOfMonth(randomDayInMonth),
            end: endOfMonth(randomDayInMonth)
          })
        )
      })
    )

    // Reset slider on available days change
    this.availableDays$.subscribe(() => {
      this.slideDayPicker?.nativeElement.swiper.slideTo(0, 400, false)
    })

    // Calculate if calendar is available now
    this.isAvailableNow$ = this.currentDayAvailabilities$.pipe(
      map((slots: Availability[]) => {
        const nextThreeSlotsAreAvailable = _every(
          _take(
            slots,
            this.minimumBookingSlots + 2
          ),
          ['isAvailable', true]
        )
        if (slots.length > 0) {
          const nextSlotIsCloseToNow = isAfter(addMinutes(this.currentDay, 5), set(new Date(), this.parseTime(slots[0].from)))
          return nextSlotIsCloseToNow
            && nextThreeSlotsAreAvailable
            && slots.length > 5
        } else {
          return false;
        }
      }),
      distinctUntilChanged(),
    )

    this.isCurrentDaySelected$ = this.selectedDay$.pipe(
      map((selectedDay: Date) => {
        return isSameDay(selectedDay, this.currentDay);
      })
    );

    // If not available now, set selection type as schedule
    this.isAvailableNow$.pipe(
      filter((isAvailableNow: boolean) => !isAvailableNow),
      take(1)
    ).subscribe(() => {
      this.selectionType$.next('schedule')
    })

    this.availableUntil$ = this.currentDayAvailabilities$.pipe(
      skipUntil(
        this.isAvailableNow$.pipe(
          filter((isAvailableNow: boolean) => isAvailableNow)
        )
      ),
      filter((slots: Availability[]) => slots.length > 5),
      map((slots: Availability[]) => {
        const lastAvailable = _find(slots, ['isAvailable', false])
        const slot = _isUndefined(lastAvailable) ? _last(slots) : lastAvailable
        return set(
          slot.to === '00:00:00' ? addDays(new Date(), 1) : new Date(),
          this.parseTime(slot.to)
        )
      })
    )

    this.availableToSlots$.subscribe((slots: Availability[][]) => {
      if (slots.length) {
        let firstAvailable = null;
        slots.every(chunk => {
          firstAvailable = _find(chunk, ['isAvailable', true]);
          if (!firstAvailable) return true;
        });
        this.selectTo(firstAvailable);
      }
    });

    this.availableDurations$ = this.availableUntil$.pipe(
      map((until: Date) => {
        const diff = differenceInMinutes(until, new Date())
        const sub = diff % 5
        return _range(15, (Math.abs(diff) - sub), 5)
      })
    )

    const storeDispatchOnSelectionChange = combineLatest([
      this.selectedFrom$,
      this.selectedTo$,
    ]).pipe(
      withLatestFrom(this.selectedDay$),
    ).subscribe(([[from, to], day]: [[Availability, Availability], Date]) => {
      if (from) {
        this.store.dispatch(setStart({ start: set(day, this.parseTime(from.from))}))
      }
      if (to) {
        this.store.dispatch(setEnd({ end: set(day, this.parseTime(to.to))}))
      }
      // this.onSelectionChange.emit({ 
      //   from: set(day, this.parseTime(from.from)),
      //   to: set(day, this.parseTime(to.to)),
      // })
    })

    this.subscriptions$.add(storeDispatchOnSelectionChange);

    const defaultFromSelection = combineLatest([
      this.selectionType$.pipe(filter(x => x === 'now')),
      this.currentDayAvailabilities$,
      this.selectedDuration$.pipe(defaultIfEmpty(0))
    ]).subscribe(([type, availabilities, duration]) => {
      const count = duration / 5
      this.selectedFrom$.next(availabilities[0])
      this.selectedTo$.next(availabilities[count - 1])
    })

    this.subscriptions$.add(defaultFromSelection);

    this.noAvailableSlotForSelectedDay$ = this.availabilities$.pipe(
      map((a: any) => a.find((slot: Availability) => slot.isAvailable) || []),
      map((availabilites: Availability[]) => availabilites.length < 5)
    )

    const swapDayIfNoSlots = this.noAvailableSlotForSelectedDay$
      .pipe(
        filter((noSlotAvailable: boolean) => noSlotAvailable),
        withLatestFrom(this.selectedDay$, this.availableDays$),
      )
      .subscribe(([, selectedDay, availableDays]: [boolean, Date, Date[]]) => {
        if (!this.pod.accessExpiresToday) {
          const nextDay = addDays(selectedDay, 1);
          const index = availableDays.findIndex(date => isSameDay(date, nextDay));
          this.selectDay(nextDay, index);
        }
      })

    this.subscriptions$.add(swapDayIfNoSlots);
  }

  ngOnDestroy(): void {
    this.subscriptions$.unsubscribe()
  }

  setAvailableMonths() {
    const maxMonthSelectable = 4
    this.availableMonths = []

    for (let i = 0; i < maxMonthSelectable; i++) {
      let nextMonthNumber = i + getMonth(this.currentDay)
      if (nextMonthNumber >= 12) {
        nextMonthNumber = (getMonth(this.currentDay) + i) % 12
      }
      this.availableMonths.push(nextMonthNumber)
    }
  }

  slideChangeTransitionStart(event) {
    this.availableDays$.pipe(take(1)).subscribe((days: Date[]) => {
      this.selectedDay$.next(days[event.srcElement.swiper.realIndex])
    })
  }

  selectDay(day: Date, index: number) {
    this.slideDayPicker.nativeElement.swiper.slideTo(index, 400, false)
    this.selectedDay$.next(day)
  }

  selectFrom(slot: Availability) {
    this.selectedFrom$.next(slot)
  }

  selectTo(slot: Availability) {
    this.selectedTo$.next(slot)
  }

  get minimumBookingSlots() {
    return this.pod?.minimum_booking_slots
  }

  parseTime(time: string) {
    const [hours, minutes, seconds] = time.split(':').map(Number)
    return { hours, minutes, seconds }
  }

  monthToDate(month: number) {
    return setMonth(new Date(), month)
  }

  timepickerSlidePrev(selection: 'Start' | 'End'): void {
    this[`slideTimepicker${selection}`]?.nativeElement.swiper.slidePrev();
  }

  timepickerSlideNext(selection: 'Start' | 'End'): void {
    this[`slideTimepicker${selection}`]?.nativeElement.swiper.slideNext();
  }

  timepickerSlideToIndex(selection: 'Start' | 'End', index: Number): void {
    this[`slideTimepicker${selection}`]?.nativeElement.swiper.slideTo(index);
  }

  selectFirstAvailableSlot(availabilities: Availability[]) {
    const firstAvailableIndex = findIndex(availabilities, ['isAvailable', true]);
    this.selectFrom(availabilities[firstAvailableIndex]);
    if (firstAvailableIndex > -1) {
      this.timepickerSlideToIndex('Start', Math.floor(firstAvailableIndex/this.chunkSize));
    }
  }

}
