import { Component, OnInit } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Store } from '@ngrx/store';
import { clearRide } from 'src/app/store/actions/ride.actions';
import { Observable} from 'rxjs';
import { map, filter, take } from 'rxjs/operators';
import { Booking } from 'src/app/types/booking';
import { selectCurrentBooking, selectQuestionnaire } from 'src/app/store/selectors/ride.selectors';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';

@Component({
  selector: 'kabin-ride-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss'],
})
export class CompletedComponent  implements OnInit {

  booking$: Observable<Booking>;
  questionnaire$: Observable<string>;
  pod: any;
  checkinContinue: boolean;

  constructor(
    private store: Store
  ) {
    if (Capacitor.isNativePlatform()) {
      StatusBar.setStyle({style: Style.Dark});
    }
    this.booking$ = this.store.select(selectCurrentBooking)
    this.questionnaire$ = this.store.select(selectQuestionnaire)
    this.booking$.pipe(
      take(1),
    ).subscribe((booking: Booking) => {
      this.pod = booking.pod;
      this.checkinContinue = booking.checkin.continue;
    });
  }

  ngOnInit(
  ) {}

  async openInBrowser(url: string) {
    await Browser.open({ url });
  }

  handleClose() {
    this.store.dispatch(clearRide())
  }
}
