import { createReducer, on } from "@ngrx/store";
import { Booking } from "src/app/types/booking";
import {
  createBooking,
  createBookingSuccess,
  createBookingFailure,
  accessBookingFailure,
  accessBookingSuccess,
  setTicket,
  setCoupon,
  setStart,
  setEnd,
  setSelectedBillingAddress,
  setSelectedPaymentMethod,
  setPod,
  clearBookingState,
} from 'src/app/store/actions/booking.actions';
import { clearBookingError } from "../actions/ride.actions";
import { BillingAddress, Coupon, PaymentMethod } from "src/app/types/payment";
import { Pod } from "src/app/types/pod";

export interface BookingState {
  pod: Pod,
  coupon: Coupon,
  start: Date,
  end: Date,
  selectedBillingAddress: BillingAddress,
  selectedPaymentMethod: PaymentMethod,
  error: any,
  loading: boolean,
  ticket: string,
}

export const initialState: BookingState = {
  pod: null,
  start: null,
  end: null,
  coupon: null,
  selectedBillingAddress: null,
  selectedPaymentMethod: null,
  error: null,
  loading: false,
  ticket: null,
};

const onLoading = (state: BookingState): BookingState => {
  return {
    ...state,
    loading: true,
  }
}

const onFailure = (state: BookingState, { error }): BookingState => {
  return {
    ...state,
    loading: false,
    error,
  }
}

const onSuccess = (state: BookingState): BookingState => {
  return {
    ...state,
    loading: false,
    error: null,
  }
}

const onClearBookingError = (state: BookingState): BookingState => {
  return {
    ...state,
    error: null,
  }
}

const onSetCoupon = (state: BookingState, { coupon }: { coupon: Coupon }): BookingState => {
  return {
    ...state,
    coupon,
  }
}

const onSetTicket = (state: BookingState, { ticket }: { ticket: string }): BookingState => {
  return {
    ...state,
    ticket,
  }
}

const onSetStart = (state: BookingState, { start }: { start: Date }): BookingState => {
  return {
    ...state,
    start,
  }
}

const onSetEnd = (state: BookingState, { end }: { end: Date }): BookingState => {
  return {
    ...state,
    end,
  }
}

const onSetSelectedBillingAddress = (state: BookingState, { selected }: { selected: BillingAddress }): BookingState => {
  return {
    ...state,
    selectedBillingAddress: selected,
  }
}

const onSetSelectedPaymentMethod = (state: BookingState, { selected }: { selected: PaymentMethod }): BookingState => {
  return {
    ...state,
    selectedPaymentMethod: selected,
  }
}

const onSetPod = (state: BookingState, { pod }: { pod: Pod }): BookingState => {
  return {
    ...state,
    pod,
  }
}

const onClearBookingState = (state: BookingState): BookingState => {
  return {
    ...initialState
  }
}

export const reducer = createReducer(
  initialState,
  on(clearBookingError, onClearBookingError),
  on(setCoupon, onSetCoupon),
  on(setTicket, onSetTicket),
  on(setStart, onSetStart),
  on(setEnd, onSetEnd),
  on(setSelectedBillingAddress, onSetSelectedBillingAddress),
  on(setSelectedPaymentMethod, onSetSelectedPaymentMethod),
  on(setPod, onSetPod),
  on(clearBookingState, onClearBookingState),
  on(
    createBooking,
    onLoading
  ),
  on(
    createBookingSuccess,
    onSuccess
  ),
  on(
    accessBookingFailure,
    createBookingFailure,
    onFailure
  ),
);

