export const environment = {
  production: false,
  debug: true,
  apiUrl: 'https://api.dev.kabin.fr/api/',
  apiKey: 'AIzaSyDeOs20CQPhZYjn2VfYn2WdhDHKUsYUcgM',
  stripePublicKey: 'pk_test_51HyKAtC09bJzn8ZVC4iLpKu0loqfC92nMT1a1QzFzf3TVtMjsJ0M3qyyvlo9Y59H2LJzZ0k43JdPxYTAuiVOwxiT00GThBtG7f',
  pusher: {
    host: 'soketi.webrocks.net',
    port: 443,
    scheme: 'https',
    app_id: 'kabin.dev',
    key: 'cdc94797-a59b-40a4-9f70-61a8c1e5944c',
    secret: '213d4b19-4ebe-4d08-8b9f-9b876a428b1f',
    cluster: 'mt1'
  },
  aws: {
    ACCESS_KEY_ID: 'SCW66YTDN4Y9WD8V29S3',
    SECRET_ACCESS_KEY: 'f9b06628-17ee-4a6a-8590-0e6f6a4a212e',
    DEFAULT_REGION: 'fr-par',
    BUCKET: 'kabin-dev',
    URL: 'https://kabin-dev.s3.fr-par.scw.cloud',
    ENDPOINT: 'https://s3.fr-par.scw.cloud'
  }
};
