import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { IonicModule } from '@ionic/angular';
import { LottieModule } from 'ngx-lottie';
import { TranslocoRootModule } from 'src/app/transloco-root.module';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { QRCodeModule } from 'angularx-qrcode';

import { AlertComponent } from './alert/alert.component';
import { CardButtonComponent } from './card-button/card-button.component';
import { CollapseComponent } from './collapse/collapse.component';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { KabinHeaderComponent } from './header/header.component';
import { BookingListMonthComponent } from './booking-list-month/booking-list-month.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { NotificationComponent } from './notification/notification.component';

import { PasswordPopoverComponent } from './popovers/password-popover/password-popover.component'
import { PasswordStrengthComponent } from './password-strength/password-strength.component';
import { PmrPopoverComponent } from './popovers/pmr-popover/pmr-popover.component';
import { EmailPopoverComponent } from './popovers/email-popover/email-popover.component';

import { DirectivesModule } from './../directives/directives.module'
import { AlertErrorComponent } from './alert-error/alert-error.component';
import { LoaderComponent } from './loader/loader.component';

// Account
import { AccountGeneralInformationComponent } from './account/general-information/general-information.component';
import { AccountPasswordComponent } from './account/password/password.component';
import { AccountDeleteComponent } from './account/delete/delete.component';
import { LocalizationComponent } from './localization/localization.component';
import { DateFnsModule } from 'ngx-date-fns';
import { PipesModule } from '../pipes/pipes.module';
import { AccessBookingComponent } from './access-booking/access-booking.component';
import { CheckinComponent } from './ride/checkin/checkin.component';
import { CheckoutComponent } from './ride/checkout/checkout.component';
import { RideComponent } from './ride/ride/ride.component';
import { HeaderComponent as RideHeaderComponent } from './ride/header/header.component';
import { CompletedComponent as RideCompletedComponent } from './ride/completed/completed.component';
import { PlannerComponent } from './booking/planner/planner.component';
import { PaymentComponent } from './booking/payment/payment.component';


// * RIDE COMPONENTS : only header for now
import { ProgressComponent as RideHeaderProgressComponent } from './ride/header/progress/progress.component';
import { TimerComponent as RideHeaderTimerComponent } from './ride/header/timer/timer.component';
import { ExtensionComponent as RideRideExtensionComponent } from './ride/ride/extension/extension.component';


// * REGISTER COMPONENTS
import { LanguageComponent as RegisterLanguageComponent } from './register/language/language.component';
import { CodeComponent as RegisterCodeComponent } from './register/code/code.component';
import { FormComponent as RegisterFormComponent } from './register/form/form.component';
import { PhoneComponent as RegisterPhoneComponent } from './register/phone/phone.component';


// * BOOKING PAYMENT MODAL COMPONENTS
import { AddressesComponent as BookingPaymentAddressesComponent } from './booking/payment/addresses/addresses.component';
import { CardsComponent as BookingPaymentCardsComponent } from './booking/payment/cards/cards.component';
import { CouponComponent as BookingPaymentCouponComponent } from './booking/payment/coupon/coupon.component';
import { TicketComponent as BookingTicketComponent } from './booking/payment/ticket/ticket.component';

const KABIN_COMPONENTS = [
  AlertComponent,
  CardButtonComponent,
  CollapseComponent,
  ShowHidePasswordComponent,
  KabinHeaderComponent,
  BookingListMonthComponent,
  PasswordStrengthComponent,
  PasswordPopoverComponent,
  PmrPopoverComponent,
  EmailPopoverComponent,
  FileUploadComponent,
  NotificationComponent,
  AlertErrorComponent,
  AccountGeneralInformationComponent,
  AccountPasswordComponent,
  AccountDeleteComponent,
  LocalizationComponent,
  AccessBookingComponent,
  CheckinComponent,
  CheckoutComponent,
  RideComponent,
  RideHeaderComponent,
  RideCompletedComponent,
  RideHeaderProgressComponent,
  RideHeaderTimerComponent,
  RegisterPhoneComponent,
  RegisterCodeComponent,
  RegisterFormComponent,
  RegisterLanguageComponent,
  LoaderComponent,
  RideRideExtensionComponent,
  PlannerComponent,
  PaymentComponent,
  BookingPaymentAddressesComponent,
  BookingPaymentCardsComponent,
  BookingPaymentCouponComponent,
  BookingTicketComponent,
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        DragDropModule,
        DirectivesModule,
        LottieModule,
        PipesModule,
        TranslocoRootModule,
        TranslocoLocaleModule,
        QRCodeModule,
    ],
    declarations: [
        KABIN_COMPONENTS
    ],
    exports: [
        KABIN_COMPONENTS
    ]
})
export class ComponentsModule { }
