import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Store } from '@ngrx/store';
import { BookingService } from 'src/app/services/booking.service';
import { NotificationService } from 'src/app/services/notification.service';
import { setTicket } from 'src/app/store/actions/booking.actions';
import { Pod } from 'src/app/types/pod';

@Component({
  selector: 'kabin-booking-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent  implements OnInit {

  @Output() onClose = new EventEmitter<void>();
  @Input() pod: Pod;
  @Input() defaultValue: string;

  ticket: string

  constructor(
    private bookingService: BookingService,
    private store: Store,
    private notificationService: NotificationService,
    private translocoService: TranslocoService,
  ) { }

  ngOnInit() {
    this.ticket = this.defaultValue
  }

  addTicket(): void {
    // this.bookingService.getDiscountCoupon(this.ticket).subscribe((coupon: Coupon) => {
      this.store.dispatch(setTicket({ ticket: this.ticket }));
      this.notificationService.notify({
        body: this.translocoService.translate('booking.ticket.success'),
        icon: 'rocket-outline',
      });
      this.onClose.emit();
    //   this.onClose.emit()
    // }, err => {
    //   this.notificationService.notify({
    //     body: err,
    //     icon: 'information-circle-outline',
    //     type: 'danger',
    //     apiError: true,
    //   });
    // });
  }
}
